import React from 'react';
import { ProductListingPage } from 'ui/component/product-listing-page';
import { MainColumn } from 'ui/component/main-column';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import { HeadTag } from '@silkpwa/module/react-component/head-tag';
import { createConnectPagination } from '@silkpwa/module/react-component/create-connect-pagination';
import { connectSearchPage } from '@silkpwa/module/react-component/connect-search-page';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

const ProductListing = createConnectPagination(modules => (
    modules.ecommerceSearchPage.ducks.pagination
))(ProductListingPage);

const SearchPageImpl = ({ searchTerm }) => {
    const t = usePhraseTranslater();
    return (
        <MainColumn className="maincolumn-searchpage">
            <HeadTag tag="meta" name="robots" content="noindex" />
            <DocumentTitle title={t('Search Results For: %1', searchTerm)} />
            <div className={styles.heading}>
                {t("Search results for '%1'", searchTerm)}
            </div>
            <ProductListing
                shouldDisplaySort
                shouldShowRecommendations
                emptyContent={(
                    <div><h1>{t('No Results')}</h1></div>
                )}
            />
        </MainColumn>
    );
};

const SearchPage = connectSearchPage(SearchPageImpl);

export { SearchPage as default };
